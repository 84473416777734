// @ts-nocheck

import { OrderDetailsWithMerchant } from "@brinks/common/api/Api";
import { formatCurrency } from "@brinks/common/utils";
import { useTranslation } from "react-i18next";
import { Text, Flex, Divider, Box } from "theme-ui";
import { DeleteIcon } from "@brinks/common/Icons/Components/delete";
import OrderDetailDenomination from "./OrderDetailDenomination";

interface Props {
  order: OrderDetailsWithMerchant;
  handleDeleteIconClick: () => void;
  isOrderCancelled: boolean;
  showDeleteIcon?: boolean;
}

export default function OrderDetailDenominationSummary({
  order,
  handleDeleteIconClick,
  isOrderCancelled,
  showDeleteIcon,
}: Props) {
  const coins = order?.items?.filter(
    (element) => element.denomination?.type === `Coin`
  );
  const notes = order?.items?.filter(
    (element) => element.denomination?.type === `Note`
  );
  const totalOfCoins =
    coins?.reduce(
      (sum, current) => sum + parseInt(current.totalPrice.toString(), 10),
      0
    ) || 0;
  const totalOfNotes =
    notes?.reduce(
      (sum, current) => sum + parseInt(current.totalPrice.toString(), 10),
      0
    ) || 0;
  const isNotesQty =
    (totalOfNotes > 0 && notes && notes[0].denomination?.sku?.quantity === 1) ||
    false;

  const { t } = useTranslation();

  return (
    <>
      <Flex
        mt={32}
        bg="white"
        sx={{
          p: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="order-detail-den"
      >
        <Box sx={{ width: 1000 }}>
          <Flex
            pt={20}
            sx={{
              fontWeight: "bold",
              fontSize: "medium",
              fontFamily: "heading",
              display: "flex",
              justifyContent: "space-between",
              width: "945px",
            }}
          >
            {t("ManageOrders.order_details")}
            {order &&
              order.status === "NEW" &&
              order.businessRules?.cancelAllow === true &&
              showDeleteIcon &&
              !isOrderCancelled && (
                <Flex
                  sx={{
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (order && order.status === "NEW") {
                      handleDeleteIconClick();
                    }
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      height: "80%",
                    }}
                  >
                    <DeleteIcon
                      width={34}
                      height={34}
                      color={"order_primary"}
                    />
                  </div>
                </Flex>
              )}
          </Flex>
          <Divider sx={{ width: 945 }} mt={16} mb={24} color="divider" />
          {totalOfNotes > 0 ? (
            <>
              <Flex
                mt={20}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "heading",
                  lineHeight: "location_lable",
                }}
              >
                {t("ManageOrders.notes")}
              </Flex>
              <Flex
                my={24}
                sx={{
                  width: isNotesQty ? 450 : 800,
                  justifyContent: "space-between",
                }}
              >
                <Text
                  sx={{
                    color: "shade_800",
                  }}
                >
                  {t("ManageSealBags.denomination")}
                </Text>
                {!isNotesQty ? (
                  <Text
                    sx={{
                      color: "shade_800",
                    }}
                  >
                    {t("ManageOrders.notes_per_bundle")}
                  </Text>
                ) : (
                  ""
                )}
                <Text
                  sx={{
                    color: "shade_800",
                    width: 150,
                  }}
                >
                  {isNotesQty
                    ? t("ManageOrders.notes")
                    : t("ManageOrders.no_bundles")}
                </Text>
              </Flex>
              {notes?.map((note, index: any) => (
                <OrderDetailDenomination
                  key={index}
                  orderItemDetail={note}
                  isNotesQty={isNotesQty}
                  merchantCountry={order.merchant?.country}
                />
              ))}
            </>
          ) : (
            ""
          )}

          {totalOfCoins > 0 ? (
            <>
              <Flex
                mt={45}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "heading",
                  lineHeight: "location_lable",
                }}
              >
                {t("ManageOrders.coins")}
              </Flex>
              <Flex
                my={24}
                sx={{
                  width: 800,
                  justifyContent: "space-between",
                }}
              >
                <Text
                  sx={{
                    color: "shade_800",
                  }}
                >
                  {t("ManageSealBags.denomination")}
                </Text>
                <Text
                  mr={30}
                  sx={{
                    color: "shade_800",
                  }}
                >
                  {t("ManageOrders.coins_per_role")}
                </Text>
                <Text
                  sx={{
                    color: "shade_800",
                    width: 150,
                  }}
                >
                  {t("ManageOrders.no_roles")}
                </Text>
              </Flex>
              {coins?.map((coin, index: any) => (
                <OrderDetailDenomination
                  key={index}
                  orderItemDetail={coin}
                  isNotesQty={false}
                  merchantCountry={order.merchant?.country}
                />
              ))}
            </>
          ) : (
            ""
          )}

          <Divider sx={{ width: 945 }} mt={16} mb={24} color="divider" />
          <Flex
            mt={24}
            sx={{
              width: 375,
              justifyContent: "space-between",
              fontWeight: "weight_400",
              fontSize: "body",
            }}
          >
            <Text
              sx={{
                fontWeight: "weight_400",
                fontSize: "body",
              }}
            >
              {t("ManageOrders.notes")}
            </Text>
            <Text
              sx={{
                width: 150,
                fontWeight: "weight_400",
                fontSize: "body",
              }}
            >
              {formatCurrency(
                totalOfNotes,
                order.currency,
                order.merchant?.country
              )}
            </Text>
          </Flex>
          <Flex
            mt={22}
            sx={{
              width: 375,
              justifyContent: "space-between",
            }}
          >
            <Text
              sx={{
                color: "shade_800",
                fontWeight: "weight_400",
                fontSize: "body",
              }}
            >
              {t("ManageOrders.coins")}
            </Text>
            <Text
              sx={{
                width: 150,
                fontWeight: "weight_400",
                fontSize: "body",
              }}
            >
              {formatCurrency(
                totalOfCoins,
                order.currency,
                order.merchant?.country
              )}
            </Text>
          </Flex>
          <Flex
            mt={22}
            sx={{
              width: 375,
              justifyContent: "space-between",
            }}
          >
            <Text
              sx={{
                color: "shade_800",
                fontWeight: "body",
                fontSize: "body",
                lineHeight: "label",
              }}
            >
              {t("ManageSealBags.grand_total")}
            </Text>
            <Text
              sx={{
                width: 150,
                fontSize: "heading_4",
                fontWeight: "heading",
              }}
            >
              {formatCurrency(
                order.totalAmount,
                order.currency,
                order.merchant?.country
              )}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}
