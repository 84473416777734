import moment from "moment-timezone";
import TagManager from "react-gtm-module";
import Loader from "../../../Shared/Loader";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Label,
  Text,
  Divider,
  Heading,
  Alert,
  Close,
} from "theme-ui";
import { OrderWithMerchant } from "@brinks/common/api/Api";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { ReactComponent as Back } from "@brinks/common/Icons/back_arrow.svg";
import OrderDetailDenominationSummary from "./OrderDetailDenominationSummary";
import { getOrderWithMerchant } from "@brinks/common/reducers/orderDetailsWithMerchantSlice";
import { DeleteIcon } from "@brinks/common/Icons/Components/delete";
import ConfirmationModal from "../ConfirmationModal";
import SuccessModal from "./components/SuccessModal";
import {
  resetOrderUpdateResponse,
  updateOrder,
} from "@brinks/common/reducers/orderDetailsSlice";
import { useLocation } from "react-router-dom";

interface LocationState {
  showDeleteIcon: boolean;
}

export const OrderDetails = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { showDeleteIcon } = (location.state as LocationState) || {
    showDeleteIcon: false,
  };

  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isConfirmationModalOpen, toggleConfirmationModal] = useState(false);
  const [isSuccessModalOpen, toggleSuccessModal] = useState(false);
  const [isOrderCancelled, setIsOrderCanceled] = useState(false);

  const { loadingUpdateOrder, orderUpdateResponse } = useAppSelector(
    (state) => state.orderDetailsSlice
  );

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Order | Detail view",
      },
    });
  }, []);

  const frequencyOptions = [
    { value: "ONCE", label: t("ManageOrders.once") },
    { value: "EVERY_1_WEEK", label: t("ManageOrders.one_week") },
    { value: "EVERY_2_WEEKS", label: t("ManageOrders.two_weeks") },
    { value: "EVERY_4_WEEKS", label: t("ManageOrders.four_weeks") },
  ];

  const submitCase = async () => {
    dispatch(updateOrder(order?.id));
  };

  useEffect(() => {
    if (orderUpdateResponse === "Success") {
      toggleSuccessModal(true);
      setIsOrderCanceled(true);
    }
    toggleConfirmationModal(false);
  }, [orderUpdateResponse]);

  useEffect(() => {
    if (id) {
      dispatch(getOrderWithMerchant(id));
    }
  }, [id]);

  const { order, loading } = useAppSelector(
    (state) => state.orderDetailsWithMerchantSlice
  );

  const handleDeleteIconClick = () => {
    if (order && order.status === "NEW") {
      toggleConfirmationModal((prevState) => !prevState);
    }
  };

  if (loading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  if (!order) {
    return null;
  }

  const isChangeOrder = order?.service?.name?.includes('CASH_ORDER') || false;
  const frequency = frequencyOptions?.find((f) => f.value === order?.frequency);

  const getMerchantAddress = (order: any) => { // changed order: OrderWithMerchant into any type to avoid error
    const { merchant } = order;

    if (merchant) {
      const { city, state, street, country, postalCode } = merchant;
      let addressParts = [];

      if (street) addressParts.push(street);
      if (city) addressParts.push(city);
      if (state) addressParts.push(state);
      if (country) addressParts.push(country);
      if (postalCode) addressParts.push(postalCode);

      return addressParts.join(", ");
    }

    return "---";
  };

  return (
    <>
      <PageHeader>
        <div
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                Action: "Back",
                event: "Click_event",
                Page_title: "Order | Detail view",
              },
            });
            dispatch(resetOrderUpdateResponse(""));
            navigate(`/orders/list`);
          }}
        >
          <span style={{ cursor: "pointer" }}>
            <Back />
          </span>
          <Text
            mx={10}
            sx={{
              fontSize: "body",
              color: "primary",
              cursor: "pointer",
              fontWeight: "weight_400",
            }}
          >
            {t("ManageSealBags.back")}
          </Text>
        </div>
        <Heading ml={20}>{t("Header.order_details")}</Heading>
      </PageHeader>
      <Wrapper>
        {order && (
          <>
            {isChangeOrder && (
              <OrderDetailDenominationSummary
                order={order}
                handleDeleteIconClick={handleDeleteIconClick}
                isOrderCancelled={isOrderCancelled}
                showDeleteIcon={showDeleteIcon}
              />
            )}
            <Flex
              mt={32}
              bg="white"
              sx={{
                p: 35,
                borderRadius: 8,
                flexDirection: "column",
                borderBottom: "1px solid",
                borderColor: "borderColor",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box sx={{ width: 1000 }}>
                <Flex
                  pt={20}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "medium",
                    fontFamily: "heading",
                  }}
                >
                  {t(
                    isChangeOrder
                      ? "ManageOrders.delivery_details"
                      : "ManageOrders.pickup_details"
                  )}
                </Flex>
                <Divider sx={{ width: 945 }} mt={16} mb={24} color="divider" />
                <Flex
                  data-testid="LocationDetails-field1"
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Label
                    data-testid="LocationDetails-name"
                    sx={{
                      color: "seal_bag",
                      fontSize: "subText",
                      fontWeight: "normal",
                      lineHeight: "register_bag",
                    }}
                  >
                    {t(
                      isChangeOrder
                        ? "ManageOrders.delivery_date"
                        : "ManageOrders.pickup_date"
                    )}
                  </Label>
                  <Text
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      fontStyle: "normal",
                    }}
                    mt={10}
                    data-testid="LocationDetails-name-value"
                  >
                    {moment(order.deliveryDateTime).format("DD/MM/YYYY")}
                  </Text>
                </Flex>
                <Flex
                  mt={39}
                  data-testid="LocationDetails-email"
                  sx={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Label
                    data-testid="LocationDetails-email-label"
                    sx={{
                      color: "seal_bag",
                      fontSize: "subText",
                      fontWeight: "normal",
                      lineHeight: "register_bag",
                    }}
                  >
                    {t("ManageOrders.frequency")}
                  </Label>
                  <Text
                    mt={10}
                    data-testid="LocationDetails-email-value"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      fontStyle: "normal",
                    }}
                  >
                    {frequency?.label}
                  </Text>
                </Flex>
                {frequency?.value !== "ONCE" && order.endDate ? (
                  <Flex
                    mt={39}
                    data-testid="LocationDetails-phone"
                    sx={{
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      data-testid="LocationDetails-phone-label"
                      sx={{
                        fontSize: "subText",
                        color: "seal_bag",
                        fontWeight: "normal",
                        lineHeight: "register_bag",
                      }}
                    >
                      {t("ManageOrders.end_date")} ({t("ManageOrders.optional")}
                      )
                    </Label>
                    <Text
                      mt={10}
                      data-testid="LocationDetails-phone-value"
                      sx={{
                        fontSize: "body",
                        fontWeight: "body",
                        lineHeight: "label",
                        fontStyle: "normal",
                      }}
                    >
                      {moment(order.endDate).format("DD/MM/YYYY")}
                    </Text>
                  </Flex>
                ) : (
                  ""
                )}
              </Box>
            </Flex>
            <Flex
              mt={32}
              bg="white"
              sx={{
                p: 35,
                borderRadius: 8,
                flexDirection: "column",
                borderBottom: "1px solid",
                borderColor: "borderColor",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box sx={{ width: 305 }}>
                <Flex
                  pt={20}
                  sx={{
                    fontSize: "medium",
                    fontFamily: "heading",
                    fontWeight: "heading",
                    lineHeight: "location_lable",
                  }}
                >
                  {t("ManageOrders.location_details")}
                </Flex>
                <Divider sx={{ width: 945 }} mt={16} mb={24} color="divider" />
                <Flex
                  data-testid="LocationDetails-field1"
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Label
                    data-testid="LocationDetails-name"
                    sx={{
                      color: "seal_bag",
                      fontSize: "subText",
                      fontWeight: "normal",
                      lineHeight: "register_bag",
                    }}
                  >
                    {t("ManageOrders.pickup_location")}
                  </Label>
                  <Text
                    mt={10}
                    data-testid="LocationDetails-name-value"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      fontStyle: "normal",
                    }}
                  >
                    {order.storeName}
                  </Text>
                </Flex>
                <Flex
                  mt={39}
                  data-testid="LocationDetails-phone"
                  sx={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Label
                    data-testid="LocationDetails-phone-label"
                    sx={{
                      color: "seal_bag",
                      fontSize: "subText",
                      fontWeight: "normal",
                      lineHeight: "register_bag",
                    }}
                  >
                    {t("ManageSealBags.prepared_by")}
                  </Label>
                  <Text
                    mt={10}
                    data-testid="LocationDetails-phone-value"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      fontStyle: "normal",
                    }}
                  >
                    {order.preparedBy}
                  </Text>
                </Flex>

                {order.customerReference && (
                  <Flex
                    mt={39}
                    data-testid="LocationDetails-customer-reference"
                    sx={{
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      data-testid="LocationDetails-customer-reference-label"
                      sx={{
                        color: "seal_bag",
                        fontSize: "subText",
                        fontWeight: "normal",
                        lineHeight: "register_bag",
                      }}
                    >
                      {t("ManageOrders.customer_order_label")}
                    </Label>
                    <Text
                      mt={10}
                      data-testid="LocationDetails-customer-reference-value"
                      sx={{
                        fontSize: "body",
                        fontWeight: "body",
                        lineHeight: "label",
                        fontStyle: "normal",
                      }}
                    >
                      {order.customerReference}
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
            <Flex
              mt={32}
              bg="white"
              sx={{
                p: 35,
                borderRadius: 8,
                flexDirection: "column",
                borderBottom: "1px solid",
                borderColor: "borderColor",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Flex
                  pt={20}
                  sx={{
                    fontSize: "medium",
                    fontFamily: "heading",
                    fontWeight: "heading",
                    lineHeight: "location_lable",
                  }}
                >
                  {t("ManageOrders.merchant_details")}
                </Flex>
                <Divider sx={{ width: 945 }} mt={16} mb={24} color="divider" />
                <Flex
                  data-testid="MerchantDetails-field1"
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Label
                    data-testid="MerchantDetails-name"
                    sx={{
                      color: "seal_bag",
                      fontSize: "subText",
                      fontWeight: "normal",
                      lineHeight: "register_bag",
                    }}
                  >
                    {t("ManageOrders.merchant_name")}
                  </Label>
                  <Text
                    mt={10}
                    data-testid="MerchantDetails-name-value"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      fontStyle: "normal",
                    }}
                  >
                    {order.merchant?.name || "---"}
                  </Text>
                </Flex>
                <Flex
                  mt={39}
                  data-testid="MerchantDetails-address"
                  sx={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Label
                    data-testid="MerchantDetails-address-label"
                    sx={{
                      color: "seal_bag",
                      fontSize: "subText",
                      fontWeight: "normal",
                      lineHeight: "register_bag",
                    }}
                  >
                    {t("ManageOrders.merchant_address")}
                  </Label>
                  <Text
                    mt={10}
                    data-testid="MerchantDetails-address-value"
                    sx={{
                      fontSize: "body",
                      fontWeight: "body",
                      lineHeight: "label",
                      fontStyle: "normal",
                    }}
                  >
                    {getMerchantAddress(order)}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </>
        )}
      </Wrapper>
      <ConfirmationModal
        toggleConfirmationModal={toggleConfirmationModal}
        isConfirmationModalOpen={isConfirmationModalOpen}
        submit={submitCase}
        confirmationMsg={t("ManageOrders.please_confirm_cancel_order")}
        btnText={t("ManageOrders.cancel_order")}
      />
      <SuccessModal
        toggleSuccessModal={toggleSuccessModal}
        isSuccessModalOpen={isSuccessModalOpen}
      />
      {orderUpdateResponse && orderUpdateResponse === "Error" && (
        <Alert
          data-testid="error-alert"
          sx={{
            position: "fixed",
            left: "50%",
            top: "13%",
            width: 496,
            boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
            fontSize: "subText",
            fontWeight: "weight_400",
          }}
        >
          {t("ManageOrders.order_cant_be_canceled")}

          <Close
            ml="auto"
            sx={{
              cursor: "pointer",
            }}
            mr={-2}
            onClick={() => dispatch(resetOrderUpdateResponse(""))}
            data-testid="error-alert-close-icon"
          />
        </Alert>
      )}
    </>
  );
};
