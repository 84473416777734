import { Card, Box, Text, Flex, Divider } from "theme-ui";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import { formatCurrency } from "@brinks/common/utils";

export const GrandTotal = () => {
  const { t } = useTranslation();
  const { grandTotal, declaredValues, selectedLocation } = useAppSelector(
    (state) => state.registerSealbagSlice
  );

  const merchantCurrency = selectedLocation.merchant?.currency;
  const merchantCountry = selectedLocation.merchant?.country;

  return (
    <Box sx={{ width: 1109 }}>
      <Card
        bg="white"
        data-testid="LocationDetails-container-card1"
        sx={{
          alignItems: "center",
          margin: "0 auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          padding: "32px 32px",
        }}
      >
        <Box>
          <Flex
            sx={{
              fontSize: "medium",
              fontFamily: "heading",
              fontWeight: "bold",
            }}
          >
            {t("ManageSealBags.amount")}
          </Flex>
          <Divider mt={16} color="divider" />
          {declaredValues?.map((el: any, index: number) => {
            if (el.denominationQuantity > 0)
              return (
                <Flex
                  key={el.denominationId}
                  sx={{
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    width: 305,
                    justifyContent: "space-between",
                    borderBottom:
                      index + 1 === declaredValues.length
                        ? "1px solid"
                        : "1px dashed",
                    borderColor: "borderColor",
                  }}
                >
                  <Flex
                    sx={{
                      fontSize: "body",
                      color: "seal_bag",
                      lineHeight: "label",
                      fontWeight: "normal",
                    }}
                  >
                    {formatCurrency(
                      el.faceValue,
                      merchantCurrency,
                      merchantCountry
                    )}{" "}
                    x {el.denominationQuantity}
                  </Flex>
                  <Flex
                    sx={{
                      float: "right",
                    }}
                  >
                    {formatCurrency(
                      el.declaredValue,
                      merchantCurrency,
                      merchantCountry
                    )}
                  </Flex>
                </Flex>
              );
          })}
          <Flex
            sx={{
              paddingTop: "12px",
              paddingBottom: "12px",
              display: "flex",
              width: 305,
              justifyContent: "space-between",
            }}
          >
            <Text
              sx={{
                fontSize: "body",
                lineHeight: "label",
                fontStyle: "normal",
              }}
            >
              {t("ManageSealBags.grand_total")}
            </Text>
            <Text
              sx={{
                fontSize: "medium",
                lineHeight: "location_lable",
                color: "total",
              }}
            >
              {formatCurrency(grandTotal, merchantCurrency, merchantCountry)}
            </Text>
          </Flex>
        </Box>
      </Card>
    </Box>
  );
};
export default GrandTotal;
